import { Injectable } from '@angular/core';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { ReplaySubject } from 'rxjs';
import { take } from 'rxjs/operators';
import { map } from 'lodash'
import { LANGUAGE, LANGUAGE_CODE_SUPPORTED, LANGUAGE_KEY, LANGUAGE_LOCAL_STOGRAGE } from '@core/contants';
import { getCookie } from '@core/helpers';
@Injectable({ providedIn: 'root' })
export class LanguageService {
  language$ = new ReplaySubject<LangChangeEvent>(1);
  
  constructor(private translateService: TranslateService
  ) {
  }

  setInitState() {
    const langSupported = map(LANGUAGE, 'code')
    this.translateService.addLangs(langSupported);
    const browserLang = this.getCachedLanguage();
    this.setLang(browserLang);
  }

  getCachedLanguage() {
    let lang = getCookie(LANGUAGE_KEY)
    if (lang) {
      lang = lang.toLowerCase();
      switch (true) {
        case lang.includes(LANGUAGE_CODE_SUPPORTED.en):
          lang = LANGUAGE_CODE_SUPPORTED.en
          break;
        case lang.includes(LANGUAGE_CODE_SUPPORTED.vi):
        default:
          lang = LANGUAGE_CODE_SUPPORTED.vi
      }
    }
    return lang || this.translateService.getBrowserLang();
  }

  setLang(lang: string) {
    this.translateService.onLangChange.pipe(take(1)).subscribe(result => {
      this.language$.next(result);
    });
    this.translateService.use(lang);
    let d: Date = new Date();
    document.cookie = `${LANGUAGE_KEY}=; expires=${d.getTime() - 365 * 24 * 60 * 60 * 1000}; path=/`;
    document.cookie = `${LANGUAGE_KEY}=c%3D${lang}%7Cuic%3D${lang}; expires=${d.getTime() + 365 * 24 * 60 * 60 * 1000}; path=/`;
    localStorage.setItem(LANGUAGE_LOCAL_STOGRAGE, lang);
  }

  getLanguageSupported() {
    let lang = this.getCachedLanguage()
    return lang.includes(LANGUAGE_CODE_SUPPORTED.vi) ? LANGUAGE_CODE_SUPPORTED.vi : LANGUAGE_CODE_SUPPORTED.en
  }
}
