import { Injectable, Injector, ErrorHandler } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { ConfirmModalComponent } from '@root/app/shared/components';
import Swal from 'sweetalert2';

/** Passes HttpErrorResponse to application-wide error handler */
@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  modalService: BsModalService
  constructor(
    private injector: Injector,
    private translateService: TranslateService
  ) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: any) => {
        if (error instanceof HttpErrorResponse) {
          const appErrorHandler = this.injector.get(ErrorHandler);
          appErrorHandler.handleError(error);

          let message = error.error.title
            ? this.translateService.instant(`ERROR.${error.error.title}`)
            : this.translateService.instant(
              'ERROR.ErrorCommon',
            )
          if (error.error.data) {
            const errorArray = Object.values(error.error.data).flat();
            if (errorArray.length > 0) {
              message = this.translateService.instant(`ERROR.${error.error.title}`, errorArray);
            }
          }
          this.showModalError(message);
        }
        return throwError(error)
      })
    );
  }

  showModalError(message) {
    const html = `<p class="mt-3 text-sm sm:text-base">${message}</p>`
    Swal.fire({
      title: `<p>${this.translateService.instant('ERROR.errTitle')}</p>`,
      html: html,
      icon: null,
      showCancelButton: false,
      customClass: {
        title: 'sw-custome__title',
        popup: 'sw-custome__popup',
        actions: 'sw-custome__actions',
        confirmButton: 'sw-custome__confirm-button',
      },
    });
  }
}