export const LANGUAGE_KEY = '.AspNetCore.Culture';
export const LANGUAGE_LOCAL_STOGRAGE = 'LANGUAGE';

export enum LANGUAGE_CODE_SUPPORTED {
    vi = 'vi',
    en = 'en',
}

export const LANGUAGE = [
    {
        code: LANGUAGE_CODE_SUPPORTED.vi,
        currencyFormat: {
            digit: ',',
            separator: '.',
        },
        label: 'VI',
    },
    {
        code: LANGUAGE_CODE_SUPPORTED.en,
        currencyFormat: {
            digit: '.',
            separator: ',',
        },
        label: 'EN',
    },
];