import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

import { CoreModule } from './core/core.module';

import { LANGUAGE_CODE_SUPPORTED } from '@core/contants';
import { AppTranslateLoader } from '@core/loader/app-translate.loader';
import { ApiService } from '@core/services';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthorizeInterceptor } from '@core/http-interceptors/authorize.interceptor';
import { LAZYLOAD_IMAGE_HOOKS, LazyLoadImageModule, ScrollHooks } from 'ng-lazyload-image';
import { ToastrModule } from 'ngx-toastr';

@NgModule({
    imports: [
        // angular
        BrowserAnimationsModule,
        BrowserModule,

        // core
        CoreModule,

        // app
        AppRoutingModule,
        ToastrModule.forRoot(),

        //library

        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useClass: AppTranslateLoader
            },
            defaultLanguage: LANGUAGE_CODE_SUPPORTED.vi
        }),
        LazyLoadImageModule

    ],
    declarations: [AppComponent],
    providers: [
        ApiService,
        { provide: HTTP_INTERCEPTORS, useClass: AuthorizeInterceptor, multi: true },
        { provide: LAZYLOAD_IMAGE_HOOKS, useClass: ScrollHooks }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }