export const SIDEBAR_MENUS = [
    {
        name: 'VlinkGroup',
        link: 'https://vlinkgroup.net',
        keyLanguage: 'sidebarMenus.vlinkGroup'
    },
    {
        name: 'Events',
        link: '/events',
        keyLanguage: 'sidebarMenus.events'
    },
    {
        name: 'TicketLookup',
        link: '/ticket-lookup',
        keyLanguage: 'sidebarMenus.ticketLookup'
    },
]