import { LocalStorageKey, PayloadPaymentBuyTicket, ResponseOrder } from "@root/app/pages/events-page/models";

export const getqueueQRPaymentsFromLocalStorage = () => {
    try {
        const queueQRPaymentsLocalStorageString = localStorage.getItem(LocalStorageKey.QUEUE_QR_PAYMENTS);
        let queueQRPaymentsLocalStorageData = JSON.parse(queueQRPaymentsLocalStorageString);
        return queueQRPaymentsLocalStorageData;
    } catch (err) {
        return [];
    }
}

export const setQRPaymentIntoLocalStorage = (qrResult: ResponseOrder, payloadPayment: PayloadPaymentBuyTicket) => {
    const queue = {
        paymentQRCodeResult: qrResult,
        paymentQRCodePayload: payloadPayment

    };
    localStorage.setItem(LocalStorageKey.QUEUE_QR_PAYMENTS, JSON.stringify(queue))
}

export const removeAQRPaymentIntoLocalStorage = () => {
    localStorage.removeItem(LocalStorageKey.QUEUE_QR_PAYMENTS)
}