export interface PaginatedListOfEventModel {
    items?: EventModel[];
    pageNumber?: number;
    totalPages?: number;
    totalCount?: number;
    hasPreviousPage?: boolean;
    hasNextPage?: boolean;
}

export interface EventModel {
    id: string;
    name: string;
    content: string;
    endedDate: string;
    location: string;
    openedDate: string;
    phaseStatus: EventPhaseStatus;
    status: EventStatus;
    youtubeUrl: string;
    imagePath: string;
    thumbnailPath: string;
    ticketQty: number;
    soldTicketQty: number;
    isPublished: boolean;
}

export enum EventPhaseStatus
{
    Ongoing = 'Ongoing',
    Upcoming = 'Upcoming',
    Ended = 'Ended'
}

export enum EventPhaseStatusAll
{
    All = 'All',
    Ongoing = 'Ongoing',
    Upcoming = 'Upcoming',
    Ended = 'Ended',
}

export enum EventStatus
{
    New,
    Published,
    Ended,
    Cancelled,
    Deleted
}