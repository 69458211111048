import { TranslateLoader } from '@ngx-translate/core';
import { of, Observable } from 'rxjs';
import * as translationVi from '@root/assets/i18n/vi.json';
import * as translationEn from '@root/assets/i18n/en.json';

const TRANSLATIONS = {
    en: translationEn,
    vi: translationVi,
}

export class AppTranslateLoader implements TranslateLoader {
    getTranslation(lang: string): Observable<any> {
        return of(TRANSLATIONS[lang])
    }
}