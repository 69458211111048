import { PaymentMethodEnum } from "./ticket.model";

export interface PayloadPaymentBuyTicket {
    ticketTypeId: string,
    name: string,
    email: string,
    phone: string,
    paymentMethod: PaymentMethodEnum,
    quantity: number,
    system: string,
    referralId: string,
    bookingAttendeesDto: [
        {
            name: string,
            email: string,
            phone: string,
        }
    ],
    isCallBack: boolean,
    transactionId: string,
    localeSeoCode: string
}

export interface ResponseOrder {
    orderId: string,
    qrUrl: string,
    txId: string,
    expiredDate: string,
    address: string,
    amount: number
}