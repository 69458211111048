export enum DATE_FORMAT_SUPPORTED {
    DATE_LONG = 'DATE_LONG',
    DATE_SHORT = 'DATE_SHORT',
    DATE_LONG_TEXT = 'DATE_LONG_TEXT',
    DATE_SHORT_TEXT = 'DATE_SHORT_TEXT',
    DATE_SHORT_TEXT_FULL_MONTH = 'DATE_SHORT_TEXT_FULL_MONTH',
    DATETIME_LONG_TEXT = 'DATETIME_LONG_TEXT',
    DATETIME_SHORT_TEXT = 'DATETIME_SHORT_TEXT',
    DATE_SHORT_TEXT_LUCKY = 'DATE_SHORT_TEXT_LUCKY',
    DATE_SHORT_TEXT_FULL_MONTH_2 = 'DATE_SHORT_TEXT_FULL_MONTH_2',
  }
  export const DATETIME_FORMAT = {
    DATE_LONG: {
      vi: 'DD/MM/YYYY',
      en: 'MM/DD/YYYY',
    },
    DATE_SHORT: {
      vi: 'DD/MM',
      en: 'MM/DD',
    },
    DATE_LONG_TEXT: {
      vi: 'DD/MM/YYYY',
      en: 'MM Do, YYYY',
    },
    DATE_SHORT_TEXT: {
      vi: 'Do MMMM, YYYY',
      en: 'MMM Do, YYYY',
    },
    DATE_SHORT_TEXT_FULL_MONTH: {
      vi: 'Do MMMM, YYYY',
      en: 'MMMM Do, YYYY',
    },
    DATETIME_LONG_TEXT: {
      vi: 'DD/MM/YYYY HH:mm',
      en: 'MMMM Do, YYYY hh:mm A',
    },
    DATETIME_SHORT_TEXT: {
      vi: 'Do MMMM, YYYY HH:mm',
      en: 'MMM Do, YYYY hh:mm A',
    },
    DATE_SHORT_TEXT_LUCKY: {
      vi: 'Do MMM  | [lúc] hh:mm A',
      en: "MMM Do | [at] hh:mm A",
    },
    DATE_SHORT_TEXT_FULL_MONTH_2: {
      vi: 'Do MMMM',
      en: 'MMM Do',
    },
  };