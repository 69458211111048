export interface PayloadTicketsByEventId {
    pageNumber: number;
    pageSize: number;
    eventId: string;
}

export interface PaginatedListOfTicketModel {
    items?: TicketModel[];
    pageNumber?: number;
    totalPages?: number;
    totalCount?: number;
    hasPreviousPage?: boolean;
    hasNextPage?: boolean;
}

export interface TicketModel {
    id: string;
    name: string;
    description: string;
    imagePath: string;
    status: string;
    openedDate: string;
    endedDate: string;
    price: number;
    quantity: number;
    soldQuantity: number;
    paymentMethods: PaymentMethod[]
}

export interface PaymentMethod {
    paymentMethod: PaymentMethodEnum,
    purchaseFeeRate: number,
    upgradeFeeRate: number,
    purchaseStatus: PaymentMethodStatus,
    upgradeStatus: PaymentMethodStatus,
    maketPrice: number,
}

export enum PaymentCard {
    VLINKPAY,
    TRC20,
}

export enum PaymentMethodEnum {
    VMM = "VMM",
    VPC = "VPC",
    VLG = "VLG",
    USDT = "USDT",
}

export enum PaymentMethodStatus
{
    Active = "Active",
    Inactive = "Inactive"
}

export enum PaymentSystem {
    VMM_CASHBACK = 'vmm cashback',
    MOONGLE_FARM = 'moongle farm',
    VLINK_PAY = 'vlink pay',
    VLINK_EXCHANGE = 'vlink exchange'
}

export enum PaymentStatus
{
    New = "New",
    Waiting = "Waiting",
    Paid = "Paid",
    Expired = "Expired",
    Cancel = "Cancel",
}