import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";

import {ENVIRONMENT_INITIALIZER, NgModule, Optional, SkipSelf, inject} from "@angular/core";
import { HttpErrorInterceptor } from "./http-interceptors/http-error.interceptor";
import { VmmSplashScreenService } from "./services";

@NgModule({
    imports: [
        HttpClientModule
    ],
    providers: [
        {provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true},
        {
            provide : ENVIRONMENT_INITIALIZER,
            useValue: () => inject(VmmSplashScreenService),
            multi   : true,
        },
    ],
    declarations: [],
})
export class CoreModule {
    constructor(
        @Optional() @SkipSelf() parentModule: CoreModule
    ) {
        if (parentModule) {
            throw new Error('CoreModule is already loaded. Import only in AppModule!');
        }
    }
}
