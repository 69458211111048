import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http'
import { Injectable } from '@angular/core'
import { LanguageService } from '@core/services/language.service'
import { TranslateService } from '@ngx-translate/core'
import { Observable } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class AuthorizeInterceptor implements HttpInterceptor {
  constructor(
    private translateService: TranslateService,
    private languageService: LanguageService,
  ) { }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return this.processRequestWithToken(req, next);
  }

  // Checks if there is an access_token available in the authorize service
  // and adds it to the request in case it's targeted at the same origin as the
  // single page application.
  private processRequestWithToken(
    req: HttpRequest<any>,
    next: HttpHandler,
  ) {
    const currentLang = this.translateService.currentLang
    req = req.clone({
      setHeaders: {
        locale: currentLang,
        'Accept-Language': currentLang,
      },
    })

    return next.handle(req).pipe();
  }
}
